import { getPublicFormInfo, submitSmartForm } from '@/hosting/keap-forms-processing/smart-form-api';
import { RecaptchaHelper } from '@/hosting/keap-forms-processing/recaptcha-helper';
import {
    RECAPTCHA_ACTION_TOKEN, LANDING_PAGE_SOURCE_TYPE,
} from '@/hosting/keap-forms-processing/keap-forms.constants';
import { STORAGE_KEYS, STORAGE_STATE, WARN_MESSAGES } from './calendly-forms-constants';
import { getItem } from '@/hosting/storage';
import { decodeTrackingEvent } from '@/shared/tracking-event-util';

export async function addCalendlyEventListeners() {
    await scheduledEvent();
}


export async function scheduledEvent() {
    window.addEventListener('message', function(event) {
        if (event.origin.startsWith('https://calendly.com')) {
            try {
                let eventData = (typeof event.data === 'string') ? JSON.parse(event.data) : event.data;

                if (eventData.event && eventData.event === 'calendly.event_scheduled') {
                    submitForm();

                    fireTrackingEvent();
                }
            } catch (e) {
                console.error('Error processing message from Calendly:', e);
            }
        }
    });
}


async function submitForm() {
    const keys = [
        STORAGE_KEYS.STANDARD_TAG,
        STORAGE_KEYS.STANDARD_GIVEN_NAME,
        STORAGE_KEYS.STANDARD_FAMILY_NAME,
        STORAGE_KEYS.STANDARD_EMAIL_ADDRESS1,
        STORAGE_KEYS.CALENDLY_FORM_SLUG,
    ];

    const {
        [STORAGE_KEYS.STANDARD_TAG]: standardTag,
        [STORAGE_KEYS.STANDARD_GIVEN_NAME]: standardGivenName,
        [STORAGE_KEYS.STANDARD_FAMILY_NAME]: standardFamilyName,
        [STORAGE_KEYS.STANDARD_EMAIL_ADDRESS1]: standardEmailAddress1,
        [STORAGE_KEYS.CALENDLY_FORM_SLUG]: calendlyFormSlug,
    } = getSessionStorageData(keys);

    if (!calendlyFormSlug || !standardEmailAddress1) {
        console.warn(WARN_MESSAGES.MISSING_FIELDS);

        return;
    }

    try {
        const formInfoPromise = getPublicFormInfo(calendlyFormSlug, '');

        const recaptchaHelperPromise = formInfoPromise.then(({ recaptchaSiteKey }) => {
            return RecaptchaHelper(recaptchaSiteKey);
        });
        const formIdPromise = formInfoPromise.then((formInfo) => formInfo?.form?.id);

        let recaptchaToken;

        const helper = await recaptchaHelperPromise;

        if (helper) {
            recaptchaToken = await helper.getRecaptchaToken(RECAPTCHA_ACTION_TOKEN);
        }

        const postData = {
            data: {
                [STORAGE_KEYS.STANDARD_GIVEN_NAME]: standardGivenName,
                [STORAGE_KEYS.STANDARD_FAMILY_NAME]: standardFamilyName,
                [STORAGE_KEYS.STANDARD_EMAIL_ADDRESS1]: standardEmailAddress1,
                [STORAGE_KEYS.STANDARD_TAG]: standardTag,
            },
            sourceType: LANDING_PAGE_SOURCE_TYPE,
        };

        const formId = await formIdPromise;

        await submitSmartForm({
            formId, recaptchaToken, postData,
        });
    }
    catch (error) {
        console.warn(WARN_MESSAGES.SUBMISSION_ERROR, error);
    }
}

async function fireTrackingEvent() {
    let { calendlyPixelEvent } = getSessionStorageData([STORAGE_KEYS.CALENDLY_PIXEL_EVENT]);

    if (!calendlyPixelEvent) {
        return;
    }

    const { trackingType, trackingEventValue } = decodeTrackingEvent(calendlyPixelEvent);

    const trackingData = { content_name: 'Calendly Event Scheduled' };

    if (trackingType === 'fbq' && typeof window.fbq === 'function') {
        window.fbq('track', `${trackingEventValue}`, trackingData);
    }
    else if (trackingType === 'gtag' && typeof window.gtag === 'function') {
        window.gtag('event', `${trackingEventValue}`, trackingData);
    }

}


function getSessionStorageData(keys) {
    const formState = getItem(STORAGE_STATE.FORM_STATE) ?? {};
    const calendlyState = getItem(STORAGE_STATE.CALENDLY_STATE) ?? {};

    const result = {};

    keys.forEach((key) => {
        if (key === STORAGE_KEYS.STANDARD_TAG) {
            result[key] = calendlyState[key] ?? null;
        }
        else {
            result[key] = formState[key] ?? calendlyState[key] ?? null;
        }
    });

    return result;
}
