
export function encodeTrackingEvent(pixelEvent) {
    if (!pixelEvent) {
        return '';
    }
    const { selectValue, customValue, openTrack } = pixelEvent;

    if (selectValue === 'Other Event Type') {
        return `${customValue}|${openTrack}`;
    }

    return `${selectValue}|${openTrack}`;
}


export function decodeTrackingEvent(pixelEvent) {
    const pipeIndex = pixelEvent.indexOf('|');

    // We expect tracking event & tracking type to be separated by pipe
    if (pipeIndex === -1) {
        return;
    }
    const trackingType = pixelEvent.substring(pipeIndex + 1);
    const trackingEventValue = pixelEvent.substring(0, pipeIndex).replace(/\s+/g, '');

    return { trackingType, trackingEventValue };
}
